import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Logo from "../components/partials/logo"
import Link from "../components/link"
import gsap from "gsap"

const useDate = (options) => {
  const [date, setDate] = useState(new Date().toLocaleString('en-AU', { ...options }))

  useEffect(() => {
    setInterval(() => {
      setDate(new Date().toLocaleString('en-AU', { ...options }))
    }, 1000)
  }, [options])



  return date
}


const useToggleNightMode = (hour, morning, night) => {

  useEffect(() => {
    if (!document.querySelector('.after-hours')) {
      if (hour > night || hour < morning) {
        document.body.classList.add('is-dark');
        document.body.classList.add('after-hours');
      }
    }
  }, [hour, morning, night])  
}


const Header = ({ siteTitle, page, className }) => {

  const mins = useDate({ hour: 'numeric', minute: 'numeric', hour12: false })
  const hour = useDate({ day: '2-digit', month: "short" })
  useToggleNightMode(mins.split(':')[0],6,18)




  useEffect(() => {
    setTimeout(function () {
      if (!document.querySelector('.introduction--animation')) {
        document.querySelector('.masthead').classList.add('is-active');
      }
    }, 50);
    document.querySelector('.toggle--background').addEventListener("click", toggleBackground);
    document.querySelector('.menu--icon').addEventListener("click", toggleMenu);
  }, []);

  const toggleBackground = (e) => {
    document.body.classList.toggle('is-dark');
  }

  const toggleMenu = (e) => {
    if (!document.querySelector('.form-active')) {

      var menu = document.querySelector('.overlay--menu');
      var masthead = document.querySelector('.masthead');
      if (menu.classList.contains('is-active')) {
        menu.classList.remove('is-active');
        masthead.classList.remove('is-open');
        document.querySelector('.menu--copy').innerHTML = 'Menu';
        document.querySelector('.overlay--menu').classList.remove('form-active');
      } else {
        menu.classList.add('is-active');
        masthead.classList.add('is-open');
        document.querySelector('.menu--copy').innerHTML = 'Close';
      }
    } else {
      gsap.to('.contact--form', { opacity: 0, display: 'none', duration: .65 });
      gsap.to('.internal--menu', { autoAlpha: 1, display: 'block', duration: .65, delay: 0.75 });

      document.querySelector('.overlay--menu').classList.remove('form-active');


    }
  }



  return (
    <header className={"masthead pl40 pr40 " + className}>
 
      <div className="inner m-bb1 bt1 flex max-1400 ma w-100">
        <div className="one--third bb1 flex m-hide">
          <p className="m0">{hour}</p>
          <p className="m0 mla toggle--background">{mins} AEST</p> 
        </div>
        <div className="logo w-100 flex align-center justify-center m-justify-left">
          <Link className="m0 link m-flex" to='/'>
            <Logo />
          </Link>
        </div>
        <div className="one--third bb1 m-b0 flex">
          <p className="m0 link m-hide">{page}</p>
          <p className="m0 mla menu--icon"><span className="menu--copy">Menu</span> <span className="square bg-transition"></span></p>
        </div>
      </div>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
