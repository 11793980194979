import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import Spacer from './spacer'

const Team = ({ team }) =>
  team.map(({ title, image, role, paragraph, subtitle, linkedinLink, id}, index) => (
    <>
      <article className='p40 max-1400 ma flex m-wrap gap-100' key={id}>
        <picture className='w-50 m-100'>
          <GatsbyImage image={image.gatsbyImageData} alt={`Picture of ${role} - ${title}`} className='ratio-2-3 max-350 m-max-100p' />
        </picture>
        <article className='w-50 max-650 m-max-100p m-100 pr20'>
          <header className='flex justify-between align-start'>
            <div>
              <p className='h4 m0'>{title}</p>
              <p className='m0 h4 max-450 op-50'>{role}</p>
            </div>
            <a target='_blank' rel='noreferrer' className='mla op-1 h4 ul-link' href={linkedinLink}>LinkedIn</a>
          </header>
          <p className={'h2 mt30 m-mt0 m-mb50 lh-12'}>{subtitle}</p>
          <p className='op-50 m-mb0 m-mt0 tracking-wide'>{paragraph}</p>
        </article>
      </article>
      {index !== team.length - 1 && (
        <>
          <Spacer />
          <Spacer />
        </>
      )}
    </>
  ))
export default Team
