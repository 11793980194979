import * as React from "react"

const Logo = () => {
  return (
  <svg className="header--logo" width="100" height="24" viewBox="0 0 100 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7578 16.7333C17.7578 20.5331 14.7444 24 8.87968 24C2.69526 24 0 19.7998 0 15.4661H3.17099C3.2337 17.7994 4.40695 21.0669 8.87815 21.0669C13.2545 21.0669 14.4583 18.6999 14.4583 16.9327C14.4583 14.8663 13.2224 13.7327 8.84602 13.165C3.64518 12.4977 0.760241 10.5986 0.760241 6.59779C0.761771 2.93306 3.74155 0 8.91027 0C14.3956 0 16.8048 3.66633 16.9012 7.46613H13.8251C13.8251 5.3998 12.4927 2.93307 8.84755 2.93307C5.10601 2.93307 4.0276 4.86593 4.0276 6.4C4.0276 7.83276 4.63028 9.26714 9.16419 9.9007C14.079 10.6002 17.7578 11.7998 17.7578 16.7333Z" />
    <path d="M24.6035 23.7332V0.26709H27.9015V23.7348H24.6035V23.7332Z" />
    <path d="M39.0616 3.20016V10.5328H48.4476V13.4659H39.0616V20.7985H50.4775V23.7316H35.7637V0.26709H50.4775V3.20016H39.0616Z" />
    <path d="M56.9746 0.26709H65.2822C71.4972 0.26709 73.7167 3.26769 73.7167 7.46789C73.7167 10.5682 71.3397 12.9674 67.9469 13.4337C67.7557 13.4675 67.6287 13.5334 67.6287 13.7328C67.6287 13.966 67.7878 14.0335 67.9775 14.0335C71.0857 14.2008 71.9423 15.4663 72.3844 16.7672L74.7324 23.7348H70.737L69.374 18.2016C68.7714 15.7011 67.0597 14.9356 63.7617 14.9356H60.2741V23.7348H56.9761V0.26709H56.9746ZM64.9962 12.001C68.0723 12.001 70.4188 10.0343 70.4188 7.53382C70.4188 4.76799 68.8341 3.20015 65.7579 3.20015H60.2726V11.9993H64.9962V12.001Z" />
    <path d="M94.5138 18.134H84.1763L82.3683 23.7332H78.6895L86.8074 0.26709H91.8812L99.9991 23.7348H96.3203L94.5138 18.134ZM93.5945 15.201L89.9156 3.73402C89.7887 3.30146 89.6617 2.90106 89.599 2.33342C89.5669 2.13402 89.5669 1.93302 89.3451 1.93302C89.1233 1.93302 89.1233 2.13242 89.0911 2.33342C89.0269 2.89945 88.9015 3.29985 88.7745 3.73402L85.0957 15.201H93.5945Z" />
  </svg>
  )  
}

export default Logo
