import React from "react";
import InfoText from "./infoText";
import ImageText from "./imageText";


const Departments = ({ departments,className }) => (
    departments.map(({ title, subtitle, paragraph, image,videoLink },index) => (
        <ImageText  autoplay={true}  imageRatio='ratio-1-2  m-ratio-2-1' wrapperClassName={'p0 ' + className + ' ' + ((departments.length -1 !== index) ? 'mb20' : '')} className='flex-reverse' video={videoLink  } image={image} key={title} >
            <InfoText
                title={title}
             
                paragraph={subtitle}
            />
        </ImageText>
    ))
)

export default Departments