import React, { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { Swiper, SwiperSlide, } from "swiper/react";
import { GatsbyImage } from "gatsby-plugin-image"
import Spacer from './spacer'
import { gsap } from "gsap"
const Timeline = ({ milestones }) => {

    const [mobile, setMobile] = useState(false)

    useLayoutEffect(() => {
        if (navigator.maxTouchPoints > 0) {
            setMobile(true)
        }
    }, [])

    useEffect(() => {
        document.querySelector('.carousel-nav.previous').addEventListener("click", previousSlide);
        document.querySelector('.carousel-nav.next').addEventListener("click", nextSlide);
    }, []);

    const previousSlide = (e) => {
        var tl = gsap.timeline();
        tl.to('.image--wrapper', { width: document.querySelector('.image--wrapper').offsetWidth, duration: 0 });
        tl.to(".image--outer", { width: 0, duration: 0.65, ease: "power1.inOut" });
        tl.to('.swiper', { onComplete: function () { var swiper = document.querySelector('.swiper').swiper; swiper.slidePrev(); } })
        tl.to(".image--outer", { width: '100%', duration: 0.65, delay: 0.75, ease: "power1.inOut" });
    }

    const nextSlide = (e) => {
        var tl = gsap.timeline();
        tl.to('.image--wrapper', { width: document.querySelector('.image--wrapper').offsetWidth, duration: 0 });
        tl.to(".image--outer", { width: 0, duration: 0.65, ease: "power1.inOut" });
        tl.to('.swiper', { onComplete: function () { var swiper = document.querySelector('.swiper').swiper; swiper.slideNext(); } })
        tl.to(".image--outer", { width: '100%', duration: 0.65, delay: 0.75, ease: "power1.inOut" });
    }




    const slides = useMemo(() => {
        let year = 0;

        return milestones.edges[0].node.milestone.map((milestone, index) => {

            if (year !== milestone.year) {
                year = milestone.year

                return (
                    <>
                        <SwiperSlide key={milestone.year} className="swiper-no-swiping w-25 i-w-50">
                            <div className="milestone h-100 flex flex-wrap bl1 pl20">
                                <p className='year'>{milestone.year}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={milestone.title} className="swiper-no-swiping w-25 i-w-50" >
                            <div className="milestone h-100 flex flex-wrap bl1 pl20">
                                <div className="inner w-100">
                                    <p className="m0  h4">{milestone.title}</p>
                                    <Spacer className='h-50' />

                                    <div className="content op-50">
                                        {milestone.content}
                                    </div>
                                    <Spacer className='h-50' />
                                </div>
                                <div className="image w-100 max-300 mta" style={{ width: `${100 - Math.random() * 20}%` }}>
                                    <div className="image--outer">
                                        <div className="image--wrapper" >
                                            <GatsbyImage className='w-100' image={milestone.image?.gatsbyImageData} alt='Intro Image' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </>)

            } else {
                return (
                    <SwiperSlide key={milestone.title} className="swiper-no-swiping w-25">
                        <div className="milestone h-100 flex flex-wrap bl1 pl20">
                            <div className="inner w-100">
                                <p className="m0 h4">{milestone.title}</p>
                                <Spacer className='h-50  ' />
                                <div className="content op-50">
                                    {milestone.content}
                                </div>
                                <Spacer className='h-35  ' />
                            </div>
                            <div className="image w-100 max-300 mta" style={{ width: `${100 - Math.random() * 20}%` }}>
                                <div className="image--outer">
                                    <div className="image--wrapper" >
                                        <GatsbyImage className='w-100' image={milestone.image?.gatsbyImageData} alt='Intro Image' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                )
            }
        }
        )

    }, [milestones.edges])

    return (
        <div className="timeline p40 pt0">
            <Spacer className='m-show' />
            <div className="max-1400 ma">
                <div className="title-area flex">
                    <p className="m0 h2   m-mb0 ">From the beginning</p>
                    <div className="timeline-nav mla">
                        <div className="carousel-nav previous"></div>
                        <div className="carousel-nav next"></div>
                    </div>
                </div>
                <Spacer className='m-h-50' />

                <Swiper spaceBetween={50} slidesPerGroupAuto={(mobile ? true : false)} slidesPerGroup={(mobile ? 1 : 2)} slidesPerView='auto' loop={false} speed={500}>
                    {slides}
                </Swiper>
            </div>
        </div>
    )
}
export default Timeline