import { gsap } from "gsap";
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect } from "react"
import Spacer from "../components/spacer"
import ConditionallyRender from "./conditionallyRender";
import Scroll from './scroll'

const ScrollingBanner = ({ title, image, introduction, year, location, website, imageClass = '', video, videoClass,link }) => {
  
  useEffect(() => {
    gsap.fromTo('.banner--title', { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: .75, delay: 0.5, ease: "power2.inOut" });
  }, []);

  const Image = ({ className }) => {
    return (
      <div className={"max-180 h-100 w-100 mla mt20 align-self--bottom m-mt20 m-max-100 " + imageClass + ' ' + className}>
        <div className="sticky--top mb80 m-mb300" >
          <div className="ratio-2-1 banner--image">
            <GatsbyImage className="bg-image" image={image?.gatsbyImageData} alt="Intro Image" />
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className={"scrolling--banner h-100vh m-h65 background--container "}>
        <ConditionallyRender when={video}>
          <video className={"bg-image cover " + videoClass} autoPlay={true} muted={true} playsInline={true} controls={false} loop={true} >
            <source src={video} type="video/mp4" />
            <track kind="captions" srcLang="en" src='' />
          </video>
        </ConditionallyRender>
        <ConditionallyRender when={!video}>
          <GatsbyImage className='bg-image ' image={image?.gatsbyImageData} alt={title} />
        </ConditionallyRender>
      </div>

      <div className="scrolling-banner h-100vh m-h65 flex flex-wrap scrolling-banner-small ">
        <div className="max-1400 mla mra w-100 flex p40">
          <div className="flex flex-wrap flex-column w-100">
            <div className="w-100 flex-grow m-flex flex align-bottom ">
              <div className={"m0 banner--title w-100 text-will-change text-white color--transition is-dark fill-transition m-hide "}>
                <h1 className='h1 m0 pt20'>{title}</h1>
              </div>
              <Scroll />
            </div>
          </div>
        </div>
      </div>
      <div className="introduction--content m-bg-white">
        <div className='m-p20 m-show'>
          <h1 className='h1 m0'>{title}</h1>
        </div>
        <div className="pl40 pr40 flex flex-wrap">
          <div className="max-1400 mla mra w-100 bt1 m-bcb bb1 flex pt80 pb80 m-p0 m-pb100 background-will-change mh90" >
            <div className="max-700 flex flex-wrap">
              <div className="align-self--top sticky--top m-flex m-wrap mb60 m-pos-rel" >
                <p className={"m0 mt10 h4 uppercase mb40 m-w-60 m-flex-grow-1"}>
                  <p className="m-max-100    m-op-50">{title} <span className={(year ? '' : 'hide')}>- {year} - {location}</span></p></p>
                <Image className='m-show' />
                <div className="h3">
                  {introduction}
                </div>
              </div>
              <div className={"align-self--bottom m-align-self--unset m-mb100  m-hide " + (website ? '' : 'hide')}>
                { link  && <a className="link button"  target="_blank" rel="noreferrer" href={link}>Go to project site</a>}

              </div>


            </div>
            <Image className='m-hide' />

          </div>

        </div>

      </div>
      <Spacer />
    </>
  )
}

export default ScrollingBanner
