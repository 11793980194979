import React from "react";
import ConditionallyRender from "./conditionallyRender";
import Spacer from "./spacer";

const InfoText = ({ title, subTitle, largeSubTitle, paragraph, children, index, spacer = true, className, alt = false,largeSubTitleClass,padding,innerHtml }) => {
  return (
    <div className={"inner p40 ma m-pt0 m-m0 " + className}>
      <Spacer className='m-show' />
      <ConditionallyRender when={title}>
        <p className="h4 m0">{title}</p>
        <ConditionallyRender when={!alt}>
          <Spacer className={'m-show m-h-25  '} />
        </ConditionallyRender>
      </ConditionallyRender>
      <ConditionallyRender when={subTitle}>
        <p className="m0 h4 max-450 op-50">{subTitle}</p>
        <ConditionallyRender when={alt}>
          <Spacer className='m-show m-h-25 ' />
        </ConditionallyRender>
      </ConditionallyRender>

      <ConditionallyRender when={largeSubTitle}>
        <p className={"h2 max-450 m-mt0 m-mb50 " + largeSubTitleClass }>{largeSubTitle}</p>
      </ConditionallyRender>
      <ConditionallyRender when={paragraph}>
        <p  className="op-50 max-350 m-mb0 m-mt0">{paragraph}</p>
      </ConditionallyRender>
      <ConditionallyRender when={innerHtml}> 
        <div dangerouslySetInnerHTML={{__html:innerHtml}} className="op-50 max-350 m-mb0 m-mt0"></div>
      </ConditionallyRender>
      <ConditionallyRender when={children}>{children}</ConditionallyRender>
      <ConditionallyRender when={spacer}>
        <Spacer className={'m-show'} />
      </ConditionallyRender>
    </div>
  );
};
export default InfoText;
