import React from 'react'
import ImageText from './imageText'
import InfoText from './infoText'

export default function KeyAdvisors({ advisors,className }) {
    return advisors.map(({ image, title, paragraph, subtitle, videoLink }, index) => (
        <ImageText  imageRatio='ratio-1-2  m-ratio-2-1' autoplay={true} wrapperClassName={'p0 ' + className + ((advisors.length - 1 !== index) ? 'mb20' : '')} className={'flex-reverse '} video={videoLink} image={image} key={title} >
            <InfoText
                title={title}
            
                paragraph={subtitle}
            />
        </ImageText>

    )
    )
}
