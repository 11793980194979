import { graphql } from 'gatsby'
import React from 'react'
import LineDivider from '../components/LineDivider'
import Departments from '../components/departments'
import Header from '../components/header'
import KeyAdvisors from '../components/keyAdvisors'
import Layout from '../components/layout'
import NextPage from '../components/nextPage'
import ScrollingBannerLarge from '../components/scrollingBannerLarge'
import SEO from '../components/seo'
import Spacer from '../components/spacer'
import Team from '../components/team'
import Timeline from '../components/timeline'

class StoryPage extends React.Component {
	render() {
		const data = this.props.data.allDatoCmsOurStory.nodes[0]
		const team = this.props.data.allDatoCmsTeam.nodes
		const departments = this.props.data.allDatoCmsDepartment.nodes
		const advisors = this.props.data.allDatoCmsKeyAdvisor.nodes
		const seo = data.seo
		return (
			<Layout>
				<SEO title={seo?.title || 'Our Story | Siera Group'} description={seo?.description} image={seo?.image?.url} />
				<Header page='Story' className='is-dark' />
				<ScrollingBannerLarge videoClass='filter-darken' imageClass='smaller--image' image={data.heroImage} video={data.videoUrl} introduction={data.introductionText} title='Our Story' />
				<Timeline milestones={this.props.data.timeline} />
				<LineDivider />
				<div className='team--section'>
					<Team team={team} />
					<Spacer />
				</div>
				<LineDivider className='m-hide' />
				<Departments className='mb20' departments={departments} />
				<KeyAdvisors advisors={advisors} />
				<Spacer className='m-hide' />
				<NextPage title={data.nextTitle} link={data.nextLink} image={data.nextImage} />
				<Spacer className='m-hide' />
			</Layout>
		)
	}
}

export default StoryPage

export const query = graphql`
	query Story {
		allDatoCmsOurStory {
			nodes {
				videoUrl
				heroImage {
					gatsbyImageData
					url
				}
				introductionText
				nextLink
				nextTitle
				nextImage {
					gatsbyImageData
					url
				}
				seo {
					description
					title
					image {
						url
					}
				}
			}
		}
		allDatoCmsKeyAdvisor {
			nodes {
				title
				subtitle
				videoLink

				image {
					gatsbyImageData
					url
				}
			}
		}
		allDatoCmsTeam(sort: { order: ASC, fields: order }) {
			nodes {
				id
				paragraph
				linkedinLink
				title
				role
				subtitle
				videoUrl
				paragraph
				image {
					gatsbyImageData
				}
			}
		}
		allDatoCmsDepartment {
			nodes {
				title
				subtitle
				videoLink

				image {
					gatsbyImageData
					url
				}
			}
		}
		timeline: allDatoCmsTimeline(sort: { fields: milestone___year, order: ASC }) {
			edges {
				node {
					milestone {
						title
						year
						content
						image {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`
